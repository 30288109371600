import React, {useState} from 'react';

import {Link} from 'gatsby'
import Layout from '../components/layout/layout'
import {StaticImage} from 'gatsby-plugin-image'
import axios from 'axios';
import {Form, Field} from 'react-final-form';
import styled from 'styled-components'
import ContactDetails from "../components/contactDetails";

const pageTitle = "International Management Association IMA :: Contact Us"

const invalidForm = styled.input`
  color: #ff0000;
  
`


const ContactPage = () => {
    const [formCompleted, setFormCompleted] = useState(false);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [formErrors, setFormErrors] = useState({});


    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    const onSubmit = async values => {
        // setFormCompleted(true);

        setFormSubmitting(true);
        let headers = {}
        let url = "https://www.hopkins.edu.hk/en/contact/api/ima.hk/" // https://6kmzr31taa.execute-api.ap-east-1.amazonaws.com
        axios.post(url, values, {headers: headers})
            .then(response => {

                if (response.data && response.data.status && response.data.status == "okay") {
                    setFormCompleted(true);
                } else {
                    console.log(">> some errors");
                    console.log(response);

                    // parse the form errors:
                    if ('form_errors' in response.data) {
                        var d = {};
                        response.data.form_errors.forEach((e) => {
                            var k = e[0];
                            var v = e[1];
                            d[k] = v;
                        });
                        //this.form_errors = d;
                        console.log(">> form errors");
                        console.log(d);
                    }
                    setFormErrors(d);

                    setFormSubmitting(false);

                }
            })
            .catch(err => console.log(err))


        //
        // await sleep(300)
        // window.alert(JSON.stringify(values, 0, 2))
    }

    /*
    const onSubmit = async values => {
        values.preventDefault();

        setFormCompleted(true);


        let headers = {}
        let url = "https://6kmzr31taa.execute-api.ap-east-1.amazonaws.com/api/"
        axios.post(url, values, {headers: headers})
            .then(response => {
                console.log(response);
                setFormCompleted(true);
            })
            .catch(err => console.log(err))


    }*/

    return (
        <Layout pageTitle={pageTitle}>
            <Form
                onSubmit={onSubmit}
                initialValues={{state: 'okay'}}
                render={({handleSubmit, form, submitting, pristine, values}) => (

                    <>
                        <ContactDetails/>

                        {formCompleted && (
                            <div className="formCompletedDiv">
                                <hr/>

                                <h1>Thank you!</h1>

                                <p>Your message has been received. The IMA staff will contact you shortly. Thanks
                                    again.</p>
                            </div>
                        )}

                        {!formCompleted && (


                            <form onSubmit={handleSubmit}>

                                <>
                                    <div className="span-23 last" style={{overflow: "hidden", marginBottom: "60px"}}>

                                        <div className="span-1 last">&nbsp;</div>
                                        <div className="span-21" style={{
                                            borderTop: "solid 1px #aaa",
                                            paddingTop: "30px",
                                            paddingLeft: "35px",
                                            paddingRight: "35px"
                                        }}>
                                            <h2 style={{fontSize: "19px"}}>Request information</h2>

                                            <div className="span-10">

                                                <p>I am interested in the following services:</p>

                                                <fieldset style={{height: "650px"}}>
                                                    <legend>Topic interested</legend>
                                                    <br/>

                                                    <p>
                                                        <Field
                                                            name="programs"
                                                            component="input"
                                                            type="checkbox"
                                                            value="1"
                                                        />{' '}


                                                        IMA
                                                        Business Seminars – pls specify topics in remark section


                                                    </p>


                                                    <p><Field
                                                        name="programs"
                                                        component="input"
                                                        type="checkbox"
                                                        value="2"
                                                    />{' '}

                                                        In-house Corporate Training Programs – pls specify topics in
                                                        remark section
                                                    </p>

                                                    <p><Field
                                                        name="programs"
                                                        component="input"
                                                        type="checkbox"
                                                        value="3"
                                                    />{' '} Other Tailor-made Corporate Services - pls specify in remark
                                                        section</p>

                                                </fieldset>

                                                {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
                                                {/*<pre>{JSON.stringify(formErrors)}</pre>*/}
                                            </div>

                                            <div className="span-10 last">


                                                <p>&nbsp;</p>
                                                <fieldset style={{height: "650px"}}>
                                                    <legend>Personal Information</legend>
                                                    <br/>
                                                    <p><b>Name:</b> (<span
                                                        style={{
                                                            fontWeight: "bold",
                                                            color: "#ff0000"
                                                        }}>*</span> required)<br/>


                                                        {/*<input*/}
                                                        {/*type="text" id="namefield" name="name" className="text" required=""*/}
                                                        {/*pattern="\S"/>*/}


                                                        <Field
                                                            name="name"
                                                            component="input"
                                                            type="text"
                                                            placeholder="Name"


                                                        >
                                                            {({input, meta}) => (
                                                                <div>
                                                                    {meta.error && meta.touched &&
                                                                    <span
                                                                        className="text-danger small">{meta.error}</span>}

                                                                    <input {...input} type="text" placeholder=""
                                                                           id="namefield"
                                                                           className={`text  ${formErrors.name ? `borderRed` : ""} ${meta.error && meta.touched ? invalidForm : ""}`}
                                                                    />
                                                                </div>
                                                            )}

                                                        </Field>
                                                        <span className="textBoldRed">{`${formErrors.name ? formErrors.name : ""}`}</span>
                                                    </p>


                                                    <p><b>Email:</b> (<span
                                                        style={{
                                                            fontWeight: "bold",
                                                            color: "#ff0000"
                                                        }}>*</span> required)<br/>

                                                        <Field
                                                            name="email"
                                                            component="input"
                                                            type="text"
                                                            placeholder="Name"

                                                        >
                                                            {({input, meta}) => (
                                                                <div>
                                                                    {meta.error && meta.touched &&
                                                                    <span
                                                                        className="text-danger small">{meta.error}</span>}

                                                                    <input {...input} type="text" placeholder=""
                                                                           id="emailfield"
                                                                           className={`text ${formErrors.email ? `borderRed` : ""} ${meta.error && meta.touched ? invalidForm : ""}`}
                                                                    />
                                                                </div>
                                                            )}

                                                        </Field>
                                                        <span className="textBoldRed">{`${formErrors.email ? formErrors.email : ""}`}</span>

                                                    </p>

                                                    <p><b>Mobile no.:</b> (<span
                                                        style={{
                                                            fontWeight: "bold",
                                                            color: "#ff0000"
                                                        }}>*</span> required)<br/>

                                                        <Field
                                                            name="mobile"
                                                            component="input"
                                                            type="text"
                                                            placeholder=""

                                                        >
                                                            {({input, meta}) => (
                                                                <div>
                                                                    {meta.error && meta.touched &&
                                                                    <span
                                                                        className="text-danger small">{meta.error}</span>}

                                                                    <input {...input} type="text" placeholder=""
                                                                           id="mobilefield"
                                                                           className={`text ${formErrors.mobile ? `borderRed` : ""} ${meta.error && meta.touched ? invalidForm : ""}`}
                                                                    />
                                                                </div>
                                                            )}

                                                        </Field>
                                                        <span className="textBoldRed">{`${formErrors.mobile ? formErrors.mobile : ""}`}</span>


                                                    </p>

                                                    <p>Qualification:<br/>


                                                        <Field
                                                            name="qualification"
                                                            component="input"
                                                            type="text"
                                                            placeholder=""

                                                        >
                                                            {({input, meta}) => (
                                                                <div>
                                                                    {meta.error && meta.touched &&
                                                                    <span
                                                                        className="text-danger small">{meta.error}</span>}

                                                                    <input {...input} type="text" placeholder=""
                                                                           id="qualificationfield"
                                                                           className={`text ${formErrors.qualification ? `borderRed` : ""} ${meta.error && meta.touched ? invalidForm : ""}`}
                                                                    />
                                                                </div>
                                                            )}

                                                        </Field>
                                                        <span className="textBoldRed">{`${formErrors.qualification ? formErrors.qualification : ""}`}</span>

                                                    </p>

                                                    <p>Address:<br/>

                                                        <Field
                                                            name="address"
                                                            component="input"
                                                            type="text"
                                                            placeholder=""

                                                        >
                                                            {({input, meta}) => (
                                                                <div>
                                                                    {meta.error && meta.touched &&
                                                                    <span
                                                                        className="text-danger small">{meta.error}</span>}

                                                                    <input {...input} type="text" placeholder=""
                                                                           id="addressfield"
                                                                           className={`text ${formErrors.address ? `borderRed` : ""} ${meta.error && meta.touched ? invalidForm : ""}`}
                                                                    />
                                                                </div>
                                                            )}

                                                        </Field>
                                                        <span className="textBoldRed">{`${formErrors.address ? formErrors.address : ""}`}</span>

                                                    </p>

                                                    <p>Remarks:<br/>
                                                        <Field name="remarks" component="textarea" id="remarksfield"
                                                               placeholder=""/>
                                                        <span className="textBoldRed">{`${formErrors.remarks ? formErrors.remarks : ""}`}</span>

                                                    </p>
                                                </fieldset>

                                                {!formSubmitting && (
                                                    <div align="center">
                                                        <button type="submit" disabled={submitting || pristine}>Submit
                                                            this
                                                            program request now
                                                        </button>
                                                    </div>
                                                )}

                                                {formSubmitting && (
                                                    <div align="center">
                                                        Submitting.... one moment...
                                                    </div>
                                                )}
                                            </div>


                                        </div>
                                    </div>

                                </>

                            </form>
                        )}
                    </>
                )}
            />

        </Layout>
    )
}

export default ContactPage
