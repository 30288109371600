import React from "react";

const ContactDetails = () => {
    return (
        <>
            <div className="span-1">&nbsp;</div>
            <div className="span-22 last" id="breadcrumb">
                <a href="/">Home</a> &gt; Contact Us
            </div>
            <div className="span-1">&nbsp;</div>
            <div className="span-6">
                <img src="/lookandfeel/images/titles/contact_us.gif" border="0"
                     alt="Student Experience"/>

                <br clear="all"/><br clear="all"/>

                <p><b>Office Location: </b><br/>
                    International Management Association<br/>
                    Units 25-26, 1/F, Tower 1, South Seas Center<br/>
                    75 Mody Road<br/>
                    TST East, Hong Kong</p>

                <p>Tel: (852) 3111-7111<br/>
                    Fax: (852) 3188-5035<br/>
                    E-mail: <a href="mailto:information@ima.hk">information@ima.hk</a></p>
            </div>

            <div className="span-15 ">

                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1615.3280747754443!2d114.17785464325141!3d22.299600693983326!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400ef70fc03ad%3A0xcad0bd32ef71ce72!2sSouth%20Seas%20Centre%2C%2075%20Mody%20Rd%2C%20Tsim%20Sha%20Tsui%20East%2C%20Hong%20Kong!5e0!3m2!1sen!2suk!4v1657632711937!5m2!1sen!2suk"
                    width="620" height="400" style={{border: 0, marginBottom: "15px"}}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </>
    )
}

export default ContactDetails;